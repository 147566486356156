<template>
  <ul>
    <li
      v-for="user in users"
      :key="user.id">
      <router-link
        :to="{name: 'user-detail', params: {id: `${user.id}`}}"
        class="block focus:outline-none focus:bg-gray-100 hover:bg-gray-100">
        <div class="flex justify-between ml-4 py-3 pr-4 border-b border-blue-500">
          <div class="flex items-center">
            <div class="w-8">
              <img
                v-if="user.avatar !== null"
                :src="user.avatar.medium"
                :alt="$t('general.label_profile_picture')"
                class="h-8 rounded-full"
              >
              <img
                v-else
                src="@/assets/images/avatar_placeholder.svg"
                :alt="$t('general.label_profile_picture')"
                class="h-8 rounded-full"
              >
            </div>
            <div class="leading-tight ml-2">
              <p
                v-text="user.name"
                class="font-semibold"
                :class="{'text-gray-500': !user.onDuty}"></p>
              <p class="text-gray-500 uppercase text-xs truncate max-w-full">
                <span
                  v-text="user.onDuty
                    ? $t('users_list.label_on_duty')
                    : $t('users_list.label_off_duty')">
                </span>
                <span> • </span>
                <span
                  v-text="$t('users_list.label_assigned', {
                    amount: user.assignedIncidentsCount
                  })">
                </span>
              </p>
            </div>
          </div>
          <p class="ml-2 flex items-center">
            <img
              src="@/assets/images/ic_chevron_right_blue.svg"
              class="h-3 w-3"
            >
          </p>
        </div>
      </router-link>
    </li>
    <li
      v-if="users.length === 0"
      v-text="$t('users_list.label_empty_list')"
      class="ml-4 pr-4 py-3 text-md text-gray-900">
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'UsersList',
    props: {
      users: { type: Array, default: () => [] },
    },
  };
</script>
