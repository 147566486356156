var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    [
      _vm._l(_vm.users, function(user) {
        return _c(
          "li",
          { key: user.id },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "block focus:outline-none focus:bg-gray-100 hover:bg-gray-100",
                attrs: {
                  to: { name: "user-detail", params: { id: "" + user.id } }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex justify-between ml-4 py-3 pr-4 border-b border-blue-500"
                  },
                  [
                    _c("div", { staticClass: "flex items-center" }, [
                      _c("div", { staticClass: "w-8" }, [
                        user.avatar !== null
                          ? _c("img", {
                              staticClass: "h-8 rounded-full",
                              attrs: {
                                src: user.avatar.medium,
                                alt: _vm.$t("general.label_profile_picture")
                              }
                            })
                          : _c("img", {
                              staticClass: "h-8 rounded-full",
                              attrs: {
                                src: require("@/assets/images/avatar_placeholder.svg"),
                                alt: _vm.$t("general.label_profile_picture")
                              }
                            })
                      ]),
                      _c("div", { staticClass: "leading-tight ml-2" }, [
                        _c("p", {
                          staticClass: "font-semibold",
                          class: { "text-gray-500": !user.onDuty },
                          domProps: { textContent: _vm._s(user.name) }
                        }),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-gray-500 uppercase text-xs truncate max-w-full"
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  user.onDuty
                                    ? _vm.$t("users_list.label_on_duty")
                                    : _vm.$t("users_list.label_off_duty")
                                )
                              }
                            }),
                            _c("span", [_vm._v(" • ")]),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("users_list.label_assigned", {
                                    amount: user.assignedIncidentsCount
                                  })
                                )
                              }
                            })
                          ]
                        )
                      ])
                    ]),
                    _c("p", { staticClass: "ml-2 flex items-center" }, [
                      _c("img", {
                        staticClass: "h-3 w-3",
                        attrs: {
                          src: require("@/assets/images/ic_chevron_right_blue.svg")
                        }
                      })
                    ])
                  ]
                )
              ]
            )
          ],
          1
        )
      }),
      _vm.users.length === 0
        ? _c("li", {
            staticClass: "ml-4 pr-4 py-3 text-md text-gray-900",
            domProps: {
              textContent: _vm._s(_vm.$t("users_list.label_empty_list"))
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }